import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Paper, Typography, InputAdornment, CircularProgress, Button,
         Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import _ from 'lodash';
import BalanceMenu from 'components/menus/BalanceMenu';
import TextInput from 'components/inputs/TextInput';
import { formatCurrency } from 'utils/currency';
import { formatDateTime } from 'utils/dates';
import { NavLink } from 'react-router-dom';
import { hasPermission } from 'utils/auth';
import { searchTransactions } from 'actions/BalanceActions';

export class TransactionSearchPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.formRef = React.createRef();
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    if (!_.some(_.values(data), (v) => { if (v) return true })) {
      this.formRef.current.updateInputsWithError({id_eq: 'No filter provided'})
      return
    }
    this.props.searchTransactions(data);
  }

  render() {
    const { webposProfile, permissions, isFetching, transactions } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className='full-width'>
          <BalanceMenu />
        </Grid>
        <Grid item md={8} className="full-width">
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center" className="hidden-print" gutterBottom>Transaction Search</Typography>

            <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef} className="full-width hidden-print">
              <Grid container spacing={8} justify="center">
                <Grid item md={6} xs={12}>
                  <TextInput type="number" name="id_eq" label="Transaction ID" validations="maxLength:10" validationError="Must be 10 characters or less" autoFocus />

                  <TextInput name="cards_number_eq" label="Card ID" />

                  <TextInput name="cards_delivery_email_or_customer_email_cont" label="Recipient/Customer Email" />

                  {webposProfile.digital_purchases_enabled && webposProfile.digital_products.length > 0 &&
                    <TextInput name="cards_delivery_sms_number_or_customer_phone_eq" label="Recipient/Customer Mobile Number" />
                  }
                </Grid>
              </Grid>
              <Grid container spacing={16} justify="center">
                <Grid item sm={10} md={6} className="full-width">
                  <Button type='submit' variant='contained' fullWidth color='secondary' disabled={isFetching}>Search</Button>
                </Grid>
              </Grid>
            </Formsy>

            {isFetching &&
              <Grid container justify="center" className="top-space">
                <CircularProgress />
              </Grid>
            }

            {transactions && transactions.length == 0 &&
              <Typography variant="body1" align="center" gutterBottom className="top-space">No Transactions found</Typography>
            }

            {transactions && transactions.length > 0 &&
              <Grid container spacing={8} justify="center" className="top-space">
                <Grid item xs={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="dense">ID</TableCell>
                        <TableCell padding="dense">Date</TableCell>
                        <TableCell padding="dense">POS Type</TableCell>
                        {hasPermission(permissions, 'read', 'Organisation') &&
                          <TableCell padding="dense">Site</TableCell>
                        }
                        <TableCell padding="dense">Cost</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transactions.map((transaction, index) =>
                        <TableRow key={index}>
                          <TableCell padding="dense">
                            <NavLink to={'/purchases/' + transaction.id}>{transaction.id_display}</NavLink>
                          </TableCell>
                          <TableCell padding="dense">{formatDateTime(transaction.date)}</TableCell>
                          <TableCell padding="dense">{transaction.purchase_source}</TableCell>
                          {hasPermission(permissions, 'read', 'Organisation') &&
                            <TableCell padding="dense">{transaction.group}</TableCell>
                          }
                          <TableCell align="right" padding="dense">{formatCurrency(transaction.total_paid, webposProfile.currency_code)}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    webposProfile: state.session.webposProfile,
    permissions: state.session.permissions,
    isFetching: state.balance.isFetching,
    transactions: state.balance.transactions
  }
}

const mapDispatchToProps = {
  searchTransactions: (data) => searchTransactions(data)
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionSearchPage)
