import React from 'react';
import { connect } from 'react-redux';
import { Grid, Paper, Typography } from '@material-ui/core';
import ProductsMenu from 'components/menus/ProductsMenu';
import { fetchProducts, downloadProductData } from 'actions/ProductActions';

export class ProductPage extends React.Component {
  componentDidMount() {
    this.props.fetchProducts();
  }

  render() {
    const { products, webposProfile } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item md={2} className="full-width">
          <ProductsMenu />
        </Grid>
        <Grid item md={8} className="full-width" style={{minHeight: ''}}>
          <Paper className="form-container" elevation={1}>
            <Grid container spacing={8}>
              {products.map((product, index) =>
                <Grid item xs={6} key={index} style={{marginBottom: '10px'}}>
                  <Typography variant="h6" align="left" style={{fontWeight: '700'}}>{product.name}</Typography>
                  <img src={product.image} alt="Products Image" style={{maxWidth: '325px'}} />
                  {product.webpos_cards != '0' &&
                    <Typography variant="body1" align="left">{webposProfile.title} Card Numbers Remaining: {product.webpos_cards}</Typography>
                  }
                  {product.kiosk_cards != '0' &&
                    <Typography variant="body1" align="left">Kiosk Card Numbers Remaining: {product.kiosk_cards}</Typography>
                  }
                </Grid>
              )}
            </Grid>
            {products.length > 0 ?
              <Typography variant="body1" className="top-space">
                Download products as <button onClick={() => this.props.downloadProductData()} className="raw-button-link">CSV</button>
              </Typography>
            :
              <Typography variant="body1" className="top-space" align="center">No products to show</Typography>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.product.isFetching,
    products: state.product.products,
    webposProfile: state.session.webposProfile
  }
}

const mapDispatchToProps = {
  fetchProducts,
  downloadProductData
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage)
